<template>
  <div class="select" :style="{ height: height + 'px' } ">
    <div class="ctx" style="white-space: pre;">
      <div class="btn" style="visibility:hidden;">
      </div>

      <div class="btn" @click="toJob">
        <p style="margin-right: 70px;margin-left: 70px;padding-top:40px;">
          <span style="font-size: 30px;white-space: pre;">招{{"\xa0\xa0\xa0\xa0"}}聘</span>
        </p>
      </div>
      <div class="btn" @click="goFriend">
        <p style="margin-right: 70px;margin-left: 70px;padding-top:40px;">
          <span style="font-size:30px;white-space: pre;">婚{{"\xa0\xa0\xa0\xa0"}}恋</span>
        </p>
      </div>
      <div class="btn" @click="goHourse">
        <p style="margin-right: 70px;margin-left: 70px;padding-top:40px;">
          <span style="font-size: 30px;white-space: pre;">房屋租售</span>
        </p>
      </div>


    </div>

    <!-- <stab :config="config"></stab> -->

  </div>

</template>

<script>
  import {
    RequestApi
  } from "@/apis/demo.js";
  import Cookies from "js-cookie";
  import {
    getQueryVariable
  } from "@/utils/request";
  import axios from "axios";
  import qs from "qs";
  import {
    Toast
  } from "vant";
  import wx from 'weixin-js-sdk';
  export default {
    data() {
      return {
        login: true,
        height: this.getViewPortHeight(),
        config: [{
            page: "select",
            icon: require("../../assets/img/home1.png"),
            selectIcon: require("../../assets/img/home1.png"),
            text: "首页",
          },
          {
            page: "href_about",
            icon: require("../../assets/img/act0-1.png"),
            selectIcon: require("../../assets/img/act1.png"),
            text: "关于",
          },
          {
            page: "news",
            icon: require("../../assets/img/new0.png"),
            selectIcon: require("../../assets/img/new1.png"),
            text: "消息",
          },
          {
            page: "main",
            icon: require("../../assets/img/main0.png"),
            selectIcon: require("../../assets/img/main1.png"),
            text: "我的",
          },
        ],
      };
    },
    beforeMount() {},
    activated() {

    },
    mounted() {

      let url = location.href;
      const r_id = decodeURI(getQueryVariable(url, "r_id")); //只要分享人的id不为空,就存到本地
      if (r_id != '' && r_id != null && r_id != 'null') {
        Cookies.set("r_id", r_id) //推荐人编号   分享人推荐人存本地,这样只要注册,就关联上
        Toast("推荐人:" + Cookies.get("r_id"));

      }

      let openid = decodeURI(getQueryVariable(url, "openid"));
      console.log(openid);
      if (openid != '' && openid != null && openid != 'null') {
        this.autologin();
      } else {
        //判断一下，cookie如果丢失了，那么就重新登录
        if (Cookies.get("kh") == "" || Cookies.get("kh") == null) {
          //如果获取用户信息失败那就直接跳到登录
          this.$router.push("login");
          return;
        }

      }

      this.getWxConfig(); //加载js


    },
    computed: {
      //给头像传值
      imgUrl: function() {
        return this.headimg;
      },


    },
    methods: {
      //给页面注入wxjs
      async getWxConfig() {
        //获取会员部分信息
        let openurl = location.href.split("#")[0];
        let that = this;
        let uri = this.$global.domain + "/tools/post.ashx";
        var param = {
          act: "getwxconfig",
          openurl: openurl,
          sitenum: "18777777777",
          token: Cookies.get("token"),
        };
        var result = await RequestApi.post(uri, param);
        //console.log(result);
        if (result.status == "1") {
          wx.config({
            debug: false,
            appId: result.appId,
            timestamp: result.timestamp,
            nonceStr: result.nonceStr,
            signature: result.signature,
            jsApiList: [
              'onMenuShareTimeline',
              'onMenuShareAppMessage',
              'onMenuShareQQ',
              'onMenuShareQZone',
              'updateTimelineShareData',
              'updateAppMessageShareData',
              'showOptionMenu'
            ]
          });
          wx.ready(function() {
            // //console.log("js注册成功");
            //Toast("微信js注册成功");
            var shareData = {
              title: '快乐建家首页', //标题
              desc: '快乐建家,集交友,房屋租售,招聘等一起的平台，您也快来参与吧！', //介绍文字
              link: 'http://joyhome.winwinchina.com/#/select?r_id=' + Cookies.get("kh"), //打开地址,要将分享人的编号加进来
              imgUrl: 'http://peng-mall.5izg.cn/img/logo.364a3941.png' //分享的图片地址
            };
            wx.updateTimelineShareData(shareData); //分享到朋友圈
            wx.updateAppMessageShareData(shareData); //分享给好友
          });
        } else {
          Toast(result.msg);
        }
      },

      autologin() {
        let url = location.href;
        let openid = decodeURI(getQueryVariable(url, "openid"));
        console.log(openid);
        if (openid != '' && openid != null && openid != 'null') {
          const param = {
            openid: openid,
            sitenum: this.$global.sitenum,
            action: 'getUserSitenumOpenid'
          };
          const url = this.$global.domain + '/tools/MallSelect.ashx';
          axios.post(url, qs.stringify(param))
            .then(res => {
              console.log(res.data);
              if (res.data.status == 1) {
                //已经注册了
                Cookies.set("kh", res.data.account);
                Cookies.set("openid", openid);
                Cookies.set("token", res.data.token); //每次登陆，token会变化，并且根据有效期会超时
                Cookies.set("code", '86C64761F192EA595591B4D473E87F0C');

                //跳转
                this.$router.push("select");
              } else {
                //按理说, 不会出现!=1的情况

              }
            });

        }

      },
      getViewPortHeight() {
        return (
          document.documentElement.clientHeight || document.body.clientHeight
        );
      },
      goHourse() {
        //进入房产网站
        //window.location.href = "https://mall04.keduozan.com/house/index.html";
        this.$router.push({
          path: '/house_list'
        });
      },
      goFriend() {
        //进入交友网站
        this.$router.push({
          path: "/",
        });
      },
      toJob() {
        //进入招聘网站
        this.$router.push({
          path: "/job_list",
        });
      },
      // end
    },
    beforeMount() {
      // this.config[2].page = "chat"; //跳转这个人的聊天页面
      // this.config[2].userCode = "coco";
      // this.config[3].page = "deal_done"; //跳转这个人的聊天页面
      // this.config[3].house = "1";
    },
  };
</script>
<!-- <style>
  span {
    width: 5em;
  }
  span::after {
    content: '';
    display: inline-block;
    width: 100%;
  }
</style> -->
<style scoped>
  .select {
    min-height: 100vh;
    height: 100%;
    background: url("../../assets/img/loginbg1.png");
    background-size: 100% 100%;
    color: #ffffff;
    box-sizing: border-box;
  }

  .ctx {
    /* margin-top: 35%; */
    padding: 0 55px;
    text-align: center;
    /* color: #ffffff;
    box-sizing: border-box;
    background: url("../../assets/img/loginbg1.png");
    background-size: 100% 100%; */


  }

  .ctx-item {
    position: relative;
    height: 40px;
    border-radius: 20px;
    padding-left: 50px;
    padding-right: 20px;
    box-sizing: border-box;
    background-color: rgba(0, 0, 0, 0.6);
  }

  .ctx-item input {
    background: none;
    outline: none;
    border: none;
    height: 100%;
    width: 100%;
    line-height: 40px;
    font-size: 15px;
  }

  .ctx-item input::placeholder {
    color: #ffffff;
  }


  .btn {
    height: 110px;
    width: 260px;
    border-radius: 20px;
    background-color: rgb(178, 91, 252);
    line-height: 40px;
    margin-bottom: 30px;

    /* display:table-cell;
  vertical-align:middle;
  text-align:center; */

  }
</style>
